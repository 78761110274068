<template>
	<div class="row">
		<div class="col-lg-8 content-area">
			<div class="header-block">
				<h2 class="cs-title" v-if="data.title">
					<ColorSplit :data="data.title" />
				</h2>
				<CreatedUpdated :user="data.author" :time="data.updated"/>
			</div>
			<div class="cs-blog-image">
				<img :src="data.image">
			</div>
			<span v-if="data.text" class="cs-text" v-html="data.text"/>
		</div>
		<div class="col-lg-4">
			<div class="cs-blog-post-content-card">
				<a
					v-for="(post, index) in data.linkedPosts"
					:key="`linked-post-${index}`"
					class="cs-blog-post-content-linked"
					:href="post.permalink"
				>
					<div class="cs-image-container cs-image-ratio-16x9">
						<img :src="post.image" loading="lazy" :alt="post.title" />
					</div>
					<h6>
						{{ post.title }}
					</h6>
				</a>
			</div>
			<div
				class="cs-blog-post-content-card"
				style="margin-top: 30px;"
			>
				<span
					v-for="(tag, index) in data.tags"
					:key="`tag-${index}`"
					class="cs-blog-post-content-tag"
				>
					{{ tag.title }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CSBlogPostContent",
	components: {
        CreatedUpdated: () => import('../components/CSUtilsCreatedUpdated.vue'),
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: {},
	mounted() {},
}
</script>

<style lang="scss" scoped>
.header-block {
	margin-bottom: 30px;
}
.content-area{
	.cs-text {
		::v-deep {
			* {
				width: 100%;
			}
		}
	}
	.cs-blog-image img{
		width:100%;
		border-radius: 15px;
		margin-bottom: 30px;
		max-height: 300px;
		object-fit: cover;
	}
}   

.cs-blog-post-content {
	&-card {
		border-radius: 30px;
		background-color: #e4eef7;
		padding: 25px 30px;
	}

	&-linked {
		margin-bottom: 15px;
		text-decoration: none;
		color: #101922;
		display: block;

		&:focus,
		&:hover,
		&:focus-within,
		&:focus-visible {
			text-decoration: none;
			outline: none;
		}

		&:last-child {
			margin-bottom: 0px;
		}

		

		h6 {
			margin-bottom: 0px;
			margin-top: 15px;
			font-weight: 400;
			font-size: 14px;
			color: #101922;
		}
	}

	&-tag {
		margin-left: 2.5px;
		margin-right: 2.5px;
		margin-top: 5px;
		color: #101922;
		background-color: #fff;
		text-align: center;
		padding: 12px 25px 12px 25px;
		border-radius: 100px;
		font-size: 12px;
		font-weight: 400;
		border: 1px solid #fff;
		display: inline-block;
	}
}
</style>
